const validator = require('validator');

const menuToggle = document.querySelector('.toggle');
const navBar = document.querySelector('nav');
const form = document.querySelector('#kontakt-form');
const clearBtn = document.querySelector('#reset-form');

menuToggle.addEventListener('click', () => {
  menuToggle.classList.toggle('active');
  navBar.classList.toggle('mobile-state');
  navBar.classList.toggle('activated');
});

if (form) {
  function showErrMsg(elName) {
    const err = document.querySelector(elName);
    err.style.cssText = 'display: inline';
  }

  clearBtn.addEventListener('click', () => {
    const fields = [
      '#namn-error',
      '#epost-error',
      '#telefon-error',
      '#meddelande-error',
    ];
    fields.forEach((field) => {
      const fld = document.querySelector(field);
      fld.style.cssText = 'display: none';
    });
  });

  form.addEventListener('submit', (e) => {
    e.preventDefault();
    const nameValid = !validator.equals(form.querySelector('#namn').value, '');
    const emailValid = validator.isEmail(form.querySelector('#epost').value);
    const phoneValid = !validator.equals(
      form.querySelector('#telefon').value,
      ''
    );
    const msgValid = !validator.equals(
      form.querySelector('#meddelande').value.trim(),
      ''
    );

    const oneFieldIsEmpty =
      !nameValid || !emailValid || !phoneValid || !msgValid;
    const wholeFormIsEmpty =
      !nameValid && !emailValid && !phoneValid && !msgValid;

    if (wholeFormIsEmpty) {
      showErrMsg('#namn-error');
      showErrMsg('#epost-error');
      showErrMsg('#telefon-error');
      showErrMsg('#meddelande-error');
    } else {
      if (!nameValid) {
        showErrMsg('#namn-error');
      }
      if (!emailValid) {
        showErrMsg('#epost-error');
      }
      if (!phoneValid) {
        showErrMsg('#telefon-error');
      }
      if (!msgValid) {
        showErrMsg('#meddelande-error');
      }
    }

    if (wholeFormIsEmpty || oneFieldIsEmpty) return false;
    else {
      form.submit();
    }
  });
}
